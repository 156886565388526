import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import Home from './pages';
import Produkte from './pages/products';
import OnlineShop from './pages/shop';
import Kontakt from './pages/contact';
import ProductDetail from "./pages/productdetail";
import BookmarK from "./pages/bookmark";
import Cart from "./pages/cart";
import Login from "./pages/login";
import LostPassword from "./pages/lost-password";
import MyAccount from "./pages/my-account";
import Settings from "./pages/settings";
import Downloads from "./pages/downloads";
import EditAddress from "./pages/edit-address";
import PaymentMethods from "./pages/payment-methods";
import EditAccount from "./pages/edit-account";
import Orders from "./pages/orders";
import Checkout from "./pages/checkout";


function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route path='/products' element={<Produkte />} />
				<Route path='/shop' element={<OnlineShop />} />
				<Route path='/contact' element={<Kontakt />} />
				<Route path='/product/:productid' element={<ProductDetail />} />
				<Route path='/bookmark' element={<BookmarK />} />
				<Route path='/cart' element={<Cart />} />
				<Route path='/login' element={<Login />} />
				<Route path='/lost-password' element={<LostPassword />} />
				<Route path='/my-account' element={<MyAccount />} />
				<Route path='/my-account/settings' element={<Settings />} />
				<Route path='/my-account/downloads' element={<Downloads />} />
				<Route path='/my-account/edit-address' element={<EditAddress />} />
				<Route path='/my-account/payment-methods' element={<PaymentMethods />} />
				<Route path='/my-account/edit-account' element={<EditAccount />} />
				<Route path='/my-account/orders' element={<Orders />} />
				<Route path='/checkout' element={<Checkout />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
