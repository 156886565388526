import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams, Link } from 'react-router-dom';
import { product } from '../data/productsData';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ShopSlider01 from '../components/images/ShopSlider01.jpg';
import ShopSlider02 from '../components/images/ShopSlider02.jpg';
import ShopSlider03 from '../components/images/ShopSlider03.jpg';
import ShopSlider04 from '../components/images/ShopSlider04.jpg';

function ProductDetail() {
    const { productid } = useParams();
    const thisProduct = product.find((prod) => prod.productid === productid);

    const onlineshopslider = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: 8000,
        appendDots: dots => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div className='slider_dots'>
                0{i + 1}
            </div>
        )
    };

    const relatedTo = product.filter(
        prod1 => prod1.productcategory[1] === thisProduct.productcategory[1] && prod1.productid !== thisProduct.productid
    );


    return (
        <div>
            <div className='onlineshopslider_section'>
                <Container>
                    <Row>
                        <Col>
                            <Slider {...onlineshopslider}>
                                <div className='shop_slider_div'><img src={ShopSlider01} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
                                <div className='shop_slider_div'><img src={ShopSlider02} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
                                <div className='shop_slider_div'><img src={ShopSlider03} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
                                <div className='shop_slider_div'><img src={ShopSlider04} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='single_product_breadcrumbs'>
                <Container>
                    <Row>
                        <Col className='mb-5'>
                            {thisProduct.productcategory.map(productcat => (
                                <sapn><span className='single_product_breadcrumbs_title'>{productcat}</span> <span className='single_product_breadcrumbs_spac'> &#47;&#47; </span></sapn>
                            ))}
                            <span className='single_product_breadcrumbs_productname'>{thisProduct.productname}</span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='product_info_section'>
                <Container>
                    <Row className='align-items-end'>
                        <Col md={6}>
                            <img src={thisProduct.productimage} alt={thisProduct.productname} className='img-fluid single_product_image' />
                        </Col>
                        <Col md={{ span: 4, offset: 1 }} className='mb-5'>
                            <h2 className='single_product_name'>{thisProduct.productname}</h2>
                            <p className='single_product_description mb-5 pb-3'>{thisProduct.productdescription}</p>
                            <p className='single_product_price mb-4'>
                                {thisProduct.variations.map(variation => (
                                    <span><span className='single_product_variations_price'>{variation.price}</span> / {variation.stock === '0' ? <span className='stock soldout'>Nicht vorrätig</span> : <span className='stock instock'>Verfügbar</span> } </span>
                                ))}
                            </p>
                            <div className='single_product_variations'>
                                <div className='d-flex justify-content-between'>
                                    {thisProduct.variations.map(variation => (
                                        <div className='single_product_variations_div w-100'>
                                            <span className='single_product_variations_title'>
                                                {variation.volume}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Link to='/products' className='btn theme-btn mt-5'>Jetzt Kaufen</Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='related_section'>
                <Row>
                    <Col md='12'>
                        <h2 className='mb-4'>Bestseller</h2>
                    </Col>
                </Row>
                <Row>
                    {relatedTo &&
                        relatedTo.slice(0, 4).map((product) => (
                            <Col md={3} xs={6} className='shop_product_col' key={product.productid}>
                                <Link to={`/product/${product.productid}`} className='product_link'>
                                    <div className='product_image_div'>
                                        <img src={product.productimage} alt={product.productname} className='img-fluid' />
                                        {product.producttype === 'new' &&
                                            <h6 className='product_image_new'>Neu</h6>
                                        }
                                    </div>
                                    <div className='product_info text-center mt-4'>
                                        <h6 className='product_name'>{product.productname}</h6>
                                        {product.producttype === 'new' ?
                                            <h6 className='fw-medium product_price'>Coming Soon</h6>
                                            :
                                            <h6 className='fw-medium product_price'>
                                                {product.variations.slice(0, 1).map(variation => (
                                                    <span>
                                                        {variation.price} / {variation.volume}
                                                    </span>
                                                ))}
                                            </h6>
                                        }
                                    </div>
                                </Link>
                            </Col>
                        ))}
                </Row>
            </Container>
        </div>
    );
}

export default ProductDetail;
