import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { product } from "../data/productsData";
import ShopSlider01 from '../components/images/ShopSlider01.jpg';
import ShopSlider02 from '../components/images/ShopSlider02.jpg';
import ShopSlider03 from '../components/images/ShopSlider03.jpg';
import ShopSlider04 from '../components/images/ShopSlider04.jpg';

const OnlineShop = () => {

	const [filter, setFilter] = useState('alle');
	const [products, setProducts] = useState([]);

	const myVariable = filter;

	useEffect(() => {
		setProducts(product);
	}, []);

	useEffect(() => {
		setProducts([]);

		const filtered = product.map(p => ({
			...p,
			filtered: p.productcategory.includes(filter)
		}));
		setProducts(filtered);

	}, [filter]);

	const onlineshopslider = {
		dots: true,
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		vertical: true,
		verticalSwiping: true,
		autoplay: true,
		autoplaySpeed: 8000,
		appendDots: dots => (
			<div>
				<ul style={{ margin: "0px" }}> {dots} </ul>
			</div>
		),
		customPaging: i => (
			<div className='slider_dots'>
				0{i + 1}
			</div>
		)
	};

	return (
		<div>
			<div className='onlineshopslider_section'>
				<Container>
					<Row>
						<Col>
							<Slider {...onlineshopslider}>
								<div className='shop_slider_div'><img src={ShopSlider01} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
								<div className='shop_slider_div'><img src={ShopSlider02} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
								<div className='shop_slider_div'><img src={ShopSlider03} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
								<div className='shop_slider_div'><img src={ShopSlider04} alt='ShopSlider' className='img-fluid shop_slider_img' /></div>
							</Slider>
						</Col>
					</Row>
				</Container>
			</div>
			<div className='onlineshop_products_section'>
				<Container>
					<Row>
						<Col className='d-flex mb-3 product_filter_col' md={{ span: 4, offset: 8 }}>
							<h6 className={'mb-0 ' + (myVariable === 'alle' ? 'active' : '')} active={filter === 'alle'} onClick={() => setFilter('alle')} >AllE</h6>
							<h6 className={'mb-0 ' + (myVariable === 'mohnkapseln' ? 'active' : '')} active={filter === 'mohnkapseln'} onClick={() => setFilter('mohnkapseln')} >mohnkapseln</h6>
							<h6 className={'mb-0 ' + (myVariable === 'superfood' ? 'active' : '')} active={filter === 'superfood'} onClick={() => setFilter('superfood')} >Superfood</h6>
							<h6 className={'mb-0 ' + (myVariable === 'neu' ? 'active' : '')} active={filter === 'neu'} onClick={() => setFilter('neu')} >Neu</h6>
						</Col>
					</Row>
					<Row>
						<Col md='12'>
							<h2 className='mb-5'>{myVariable} Produkte</h2>
						</Col>
					</Row>
					<Row className='product__container'>
						{products.map(product =>
							product.filtered === true ?
								<Col md={3} xs={6} className='shop_product_col' key={product.productid}>
									<Link to={`/product/${product.productid}`} className='product_link'>
										<div className='product_image_div'>
											<img src={product.productimage} alt={product.productname} className='img-fluid' />
											{product.producttype === 'new' &&
												<h6 className='product_image_new'>Neu</h6>
											}
										</div>
										<div className='product_info text-center mt-4'>
											<h6 className='product_name'>{product.productname}</h6>
											{product.producttype === 'new' ?
												<h6 className='fw-medium product_price'>Coming Soon</h6>
												:
												<h6 className='fw-medium product_price'>
													{product.variations.slice(0, 1).map(variation => (
														<span>
															{variation.price} / {variation.volume}
														</span>
													))}
												</h6>
											}
										</div>
									</Link>
								</Col>
								: ''
						)}
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default OnlineShop;
