import { React } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import AmazonPay from '../components/images/AmazonPay.png';
import PayPal from '../components/images/PayPal.png';

const Checkout = () => {
    return (
        <div>
            <div className='login_section'>
                <Container>
                    <Row>
                        <Col>
                            <h2 className='page_title'>Checkout</h2>
                        </Col>
                    </Row>
                    <Row className='mb-5 pb-3'>
                        <Col>
                            <Link className='btn theme-btn theme-btn-4 me-3'>Gutscheincode eineben</Link>
                            <Link className='btn theme-btn'>Einlösen</Link>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col md={8}>
                            <Row className='mb-5'>
                                <Col md={12}><h5>Express Zahlung:</h5></Col>
                                <Col md={12}><hr className='checkout_hr' /></Col>
                                <Col md={12} className='my-3'>
                                    <img src={AmazonPay} alt='AmazonPay' className='checkout_img' />
                                    <img src={PayPal} alt='PayPal' className='checkout_img' />
                                </Col>
                                <Col md={12}><hr className='checkout_hr' /></Col>
                            </Row>
                            <Row>
                                <Col md={12} className='mb-4'><h5>Rechnungsdetails</h5></Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>*Vorname</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>*Nachname</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>Firmenname</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>UID-Nummer</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>*Land</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>Bundesland</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>*Postleitzahl</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>*Ort</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>*Telefonnummer</label>
                                </Col>
                                <Col md={6}>
                                    <input type='text' className='form-control form_input' />
                                    <label className='checkout_form_label'>*E-Mail Adresse</label>
                                </Col>
                                <Col md={12}>
                                    <p>Die mit * gekennzeichneten Wörter sind Pflichtfelder.</p>
                                    <label className='shipping_label'>
                                        <input type="checkbox" name="account_option" />
                                        Rechnungadresse entspricht nicht Lieferadresse
                                    </label>
                                </Col>
                            </Row>
                            <Row className='mt-5 pt-4'>
                                <Col md={12}><h5 className='mb-0'>Bezahlung</h5></Col>
                                <Col md={12}><hr className='checkout_hr' /></Col>
                                <Col md={12}>
                                    <label className='shipping_label'>
                                        <input type="radio" name="payment_option" />
                                        Überweisung
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="radio" name="payment_option" />
                                        Sofortüberweisung
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="radio" name="payment_option" />
                                        Kreditkarte
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="radio" name="payment_option" />
                                        PayPal
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="radio" name="payment_option" />
                                        Amazon Pay
                                    </label>
                                </Col>
                                <Col md={12}><hr className='checkout_hr' /></Col>
                                <Col md={12}>
                                    <label className='shipping_label'>
                                        <input type="checkbox" name="condition_option" />
                                        *Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="checkbox" name="condition_option" />
                                        *Ich verpflichte mich, mit dem Erwerb der Mohnkapseln diese nicht missbräuchlich sondern ausschließlich für Dekorationszwecke zu verwenden.
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="checkbox" name="condition_option" />
                                        Ich möchte den kostenlosen Newsletter abonnieren.
                                    </label>
                                </Col>
                                <Col md={12} className='mt-5'>
                                    <Link className='btn theme-btn'>zahlungspflichtig bestellen</Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row className='py-3'>
                                <h5 className='mb-3'>Deine Bestellung</h5>
                                <p className='mb-1'><span className='me-3'>1</span>Blaumohn 220 g</p>
                                <p className='mb-1'><span className='me-3'>2</span>Große Mohnkapsel 120 g</p>
                            </Row>
                            <Row className='mt-5 py-3'>
                                <h5 className='mb-3'>Warenkorb Summe</h5>
                                <Col md={6}><p className='mb-0'>Zwischensumme</p></Col>
                                <Col md={6}><p className='mb-0'>21,90€ (inkl. MwSt.)</p></Col>
                            </Row>
                            <Row className='mt-5 mb-5'>
                                <h5>Versand</h5>
                                <Col>
                                    <label className='shipping_label'>
                                        <input type="radio" name="shipping_option" value="free" />
                                        Free Shipping
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="radio" name="shipping_option" value="dhl" />
                                        DHL Express: 15,00€ (inkl. MwSt.)
                                    </label>
                                    <label className='shipping_label'>
                                        <input type="radio" name="shipping_option" value="postat" />
                                        Post AT Standard Shipping: 6,00€ (inkl. MwSt.)
                                    </label>
                                </Col>
                            </Row>
                            <Row className='py-3 mb-4'>
                                <Col md={6}><p className='mb-1 fw-bold'><b>Gesamtsumme</b></p></Col>
                                <Col md={6}><p className='mb-1 fw-bold'><b>21,90€</b></p></Col>
                                <Col md={12}>
                                    <p className='mb-1'>inkl. 0,54 € USt. 10%</p>
                                    <p className='mb-1'>inkl. 2,67 € USt. 20%</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Checkout;
