import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const Login = () => {
	return (
		<div>
			<div className='login_section'>
				<Container>
					<Row>
						<Col>
							<h2 className='page_title'>Mein Konto</h2>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<h5>Anmelden</h5>
							<Row>
								<Col md={12}>
									<input type='email' className='form-control form_input' />
									<label className='form_label login_formlabel'>*E-Mail Adresse</label>
								</Col>
								<Col md={12}>
									<input type='password' className='form-control form_input' />
									<label className='form_label login_formlabel'>*Passwort</label>
								</Col>
								<Col md={12}>
									<input type='submit' value='ANMELDEN' className='btn theme-btn' />
								</Col>
							</Row>
							<Row className='mt-5'>
								<Col md={12}>
									<Link to={`/lost-password`} className='link_text'>Passwort vergessen?</Link>
									<p>Die mit * gekennzeichneten Wörter sind Pflichtfelder.</p>
								</Col>
							</Row>
						</Col>
						<Col md={4}>
							<h5>Noch kein Konto?</h5>
							<Row>
								<Col md={12}>
									<input type='email' className='form-control form_input' />
									<label className='form_label login_formlabel'>*E-Mail Adresse</label>
								</Col>
								<Col md={12}>
									<input type='password' className='form-control form_input' />
									<label className='form_label login_formlabel'>*Passwort</label>
								</Col>
								<Col md={12}>
									<p>Ihre personenbezogenen Daten werden verwendet, um Ihre Erfahrung auf dieser Website zu unterstützen, den Zugriff auf Ihr Konto zu verwalten und für andere Zwecke, die in unserer Datenschutzerklärung beschrieben sind.</p>
								</Col>
								<Col md={12}>
									<input type='submit' value='Registrieren' className='btn theme-btn' />
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Login;
