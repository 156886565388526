import React from 'react';

const BookmarK = () => {
	return (
		<div>
			<h1>BookmarK</h1>
		</div>
	);
};

export default BookmarK;
