import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const MyAccount = () => {
    return (
        <div>
            <div className='login_section'>
                <Container>
                    <Row>
                        <Col>
                            <h2 className='page_title'>letzten Bestellungen</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default MyAccount;
