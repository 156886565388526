import { React } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Cart = () => {
	return (
		<div>
			<div className='login_section'>
				<Container>
					<Row>
						<Col>
							<h2 className='page_title'>Dein Warenkorb</h2>
						</Col>
					</Row>
					<Row className='mb-3'>
						<Col Col md={9}>
							<Table className='cart_table'>
								<Thead>
									<Tr>
										<Th>Produkt</Th>
										<Th>Anzahl</Th>
										<Th>Preis</Th>
										<Th className='text-end'>Gesamtsumme</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td>Blaumohn 220 g</Td>
										<Td>
											<span className='qtybtn minus'>-</span>
											<input type='number' className='productqty' value={1} />
											<span className='qtybtn plus'>+</span>
										</Td>
										<Td>5,90 €</Td>
										<Td className='text-end'><span className='fw-bold' >5,90 €</span> (inkl. MwSt.)</Td>
									</Tr>
									<Tr>
										<Td>Große Mohnkapsel 120 g</Td>
										<Td>
											<span className='qtybtn minus'>-</span>
											<input type='number' className='productqty' value={2} />
											<span className='qtybtn plus'>+</span>
										</Td>
										<Td>16,00 €</Td>
										<Td className='text-end'><span className='fw-bold' >32,00 €</span> (inkl. MwSt.)</Td>
									</Tr>
								</Tbody>
							</Table>
						</Col>
					</Row>
					<Row className='mt-5'>
						<Col md={4}>
							<input type='text' className='form-control coupon_input' placeholder='Gutscheincode eineben' />
							<input type='submit' value='Einlösen' className='btn theme-btn coupon_btn' />
						</Col>
						<Col className='mt-5' md={{ span: 4, offset: 1 }}>
							<h5>Warenkorb Summe</h5>
							<Row className='py-3'>
								<Col md={6}><p className='mb-0'>Zwischensumme</p></Col>
								<Col md={6}><p className='mb-0'>21,90€ (inkl. MwSt.)</p></Col>
							</Row>
							<Row className='mt-5 mb-5'>
								<h5>Versand</h5>
								<Col>
									<label className='shipping_label'>
										<input type="radio" name="shipping_option" value="free" />
										Free Shipping
									</label>
									<label className='shipping_label'>
										<input type="radio" name="shipping_option" value="dhl" />
										DHL Express: 15,00€ (inkl. MwSt.)
									</label>
									<label className='shipping_label'>
										<input type="radio" name="shipping_option" value="postat" />
										Post AT Standard Shipping: 6,00€ (inkl. MwSt.)
									</label>
								</Col>
							</Row>
							<Row className='py-3 mb-4'>
								<Col md={6}><p className='mb-1 fw-bold'><b>Gesamtsumme</b></p></Col>
								<Col md={6}><p className='mb-1 fw-bold'><b>21,90€</b></p></Col>
								<Col md={12}>
									<p className='mb-1'>inkl. 0,54 € USt. 10%</p>
									<p className='mb-1'>inkl. 2,67 € USt. 20%</p>
								</Col>
							</Row>
							<Link to='/checkout' className='btn theme-btn'>Weiter zur Kasse</Link>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Cart;
