import { React, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom'
import { ReactComponent as Brand } from '../images/Logo_MyPoppies.svg'
import SearchIcon from '../images/search_icon.svg';
import BookmarkIcon from '../images/bookmark_icon.svg';
import CartIcon from '../images/cart_icon.svg';
import SidebarIcon from '../images/sidebar_icon.svg';
import CloseIcon from '../images/close_icon.svg';
import Facebook1 from '../images/Icon_Facebook1.svg';
import Instagram1 from '../images/Icon_Instagram1.svg';
import TikTok1 from '../images/Icon_TikTok1.svg';
import './navbar.css'

function MenuNavbar() {

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    isOpen === true ? document.body.style.overflow = 'unset' : document.body.style.overflow = 'hidden';
  }

  return (
    <>
      {['lg'].map((expand) => (
        <Navbar collapseOnSelect key={expand} expand={expand}>
          <Container fluid>
            <NavLink to='/'><Brand className='nav_brand' /></NavLink>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Collapse
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            >
              <div className='main_menu_div w-100 align-items-center mt-5'>
                <Nav className='justify-content-start flex-grow-1 ps-5 main-menu'>
                  <NavLink className='nav_link' to='/'>Home</NavLink>
                  <NavLink className='nav_link' to='/products'>Produkte</NavLink>
                  <NavLink className='nav_link' to='/shop'>Online Shop</NavLink>
                  <NavLink className='nav_link' to='/contact'>Kontakt</NavLink>
                </Nav>
                <div className='rigth_menu align-items-center'>
                  <NavLink className='nav_link' to='/login'>Login</NavLink>
                  <NavLink className='nav_link' to='/'><img src={SearchIcon} alt='SearchIcon' /></NavLink>
                  <NavLink className='nav_link' to='/bookmark'><img src={BookmarkIcon} alt='BookmarkIcon' /></NavLink>
                  <NavLink className='nav_link' to='/cart'><img src={CartIcon} alt='CartIcon' /> <span className='cart_count'>0</span></NavLink>
                  <NavLink className='nav_link' onClick={ToggleSidebar}><img src={SidebarIcon} alt='SidebarIcon' /></NavLink>
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ))}
      <div className={`sidebar ${isOpen === true ? 'active' : ''}`}>
        <div className='sd-header'>
          <div className='close_sidebar' onClick={ToggleSidebar}><img src={CloseIcon} alt='CloseIcon' /></div>
        </div>
        <div className='sd-body'>
          <Brand className='nav_brand mb-5' />
          <p className='mt-3 mb-5'>Abonniere unseren kostenlosen Newsletter und bleibe auf dem aktuelle Stand in der bunten Welt von My Poppies.</p>
          <div className='pt-3 mb-5'>
            <h5>Newsletter</h5>
            <div className='sidebar_newsletter'>
              <input type='email' className='form-control sidebar_newsletter_input' placeholder='E-Mail' />
              <input type='submit' value='Absenden' className='btn theme-btn' />
            </div>
          </div>
          <div className='pt-3 mb-5'>
            <h5>Follow us</h5>
            <ul className='footer_social_ul'>
              <li className='footer_social_li'><a href='https://www.facebook.com/profile.php?id=100063972548417' target="_blank" rel="noreferrer"><img src={Facebook1} alt='Facebook' className='img-fluid footer_social_icon' /></a></li>
              <li className='footer_social_li'><a href='https://www.instagram.com/mypoppiescom/' target="_blank" rel="noreferrer"><img src={Instagram1} alt='Instagram' className='img-fluid footer_social_icon' /></a></li>
              <li className='footer_social_li'><a href='https://www.tiktok.com/' target="_blank" rel="noreferrer"><img src={TikTok1} alt='TikTok' className='img-fluid footer_social_icon' /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`sidebar-overlay ${isOpen === true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
    </>
  );
}

export default MenuNavbar;