import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { product } from "../data/productsData";
import Product05 from '../components/images/Product05.png';
import Product06 from '../components/images/Product06.png';

const Home = () => {
	return (
		<div>
			<div className='header_section'>
				<Container>
					<Row className='align-items-center'>
						<Col md='5'>
							<div className='header_section_info'>
								<h1 className='header_section_h1 mb-4 pb-3'>Created<br />By Nature.</h1>
								<p className='header_section_p mb-4 pb-3'>Entdecke sie, die Welt des Mohns. Garantiert österreichische Bio-Qualität. Made with 100% Love.</p>
								<Link to='/products' className='btn theme-btn'>Mehr Erfahren</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className='home_product_section section_paddding'>
				<Container>
					<Row>
						<Col md='12'>
							<h2 className='mb-4'>Bestseller</h2>
						</Col>
					</Row>
					<Row>
						{product.slice(0, 4).map(product =>
							<Col md={3} xs={6} className='shop_product_col' key={product.productid}>
								<Link to={`/product/${product.productid}`} className='product_link'>
									<div className='product_image_div'>
										<img src={product.productimage} alt={product.productname} className='img-fluid' />
										{product.producttype === 'new' &&
											<h6 className='product_image_new'>Neu</h6>
										}
									</div>
									<div className='product_info text-center mt-4'>
										<h6 className='product_name'>{product.productname}</h6>
										{product.producttype === 'new' ?
											<h6 className='fw-medium product_price'>Coming Soon</h6>
											:
											<h6 className='fw-medium product_price'>
												{product.variations.slice(0, 1).map(variation => (
													<span>
														{variation.price} / {variation.volume}
													</span>
												))}
											</h6>
										}
									</div>
								</Link>
							</Col>
						)}
					</Row>
				</Container>
			</div>
			<div className='home_promotion_section section_paddding_2'>
				<Container>
					<Row>
						<Col md='5'>
							<h2 className='mb-4 home_promotion_section_title'>Unser Mohn<br />Kann mehr.</h2>
							<p className='pt-3 pb-4 mb-0 home_promotion_section_text'>Es steckt Großes hinter den kleinen Kapseln.<br />Erfahre, was wir dir in Zukunft noch bieten werden.</p>
							<Link to='/products' className='btn theme-btn theme-btn-2 mt-3'>Mehr Erfahren</Link>
						</Col>
					</Row>
				</Container>
			</div>
			<div className='home_extra_product_section'>
				<Container>
					<Row>
						<Col md='6'>
							<div className='home_extra_product_col_1 d-flex align-items-center'>
								<div className='home_extra_product_info pe-3'>
									<h6 className='w_font mb-3'>Pflanzendrink</h6>
									<h2 className='w_font mb-4'>MYMo</h2>
									<p className='w_font pt-3 pb-4'>WHAT! Der erste Pflanzendrink auf Mohnbasis? Finde heraus, was sich hinter unserer neuen Marke noch alles versteckt!</p>
									<Link to='/products' className='mt-3 home_extra_product_link w_font'> &#47;&#47; Weitere Infos </Link>
								</div>
								<div className='home_extra_product_img'>
									<img src={Product05} alt='Product05' className='home_extra_product_image' />
								</div>
							</div>
						</Col>
						<Col md='6'>
							<div className='home_extra_product_col_2 d-flex align-items-center'>
								<div className='home_extra_product_info pe-3'>
									<h6 className='w_font mb-3'>Spirituosen</h6>
									<h2 className='w_font mb-4'>Punct</h2>
									<p className='w_font pt-3 pb-4'>Unser Mohn kann noch mehr: Punct. Spirituosen in höchster Qualität, vom edlen Gin bis zu den feinsten Spitzenlikören.</p>
									<Link to='/products' className='mt-3 home_extra_product_link w_font'> &#47;&#47; Weitere Infos </Link>
								</div>
								<div className='home_extra_product_img'>
									<img src={Product06} alt='Product06' className='home_extra_product_image' />
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Home;
